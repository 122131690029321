import { useGuardMeTableControls } from '../hooks/useGuardMeTableControls';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Check, ChevronDown, Filter } from 'lucide-react';
import { useState } from 'react';

type GuardMeListFilterProps = {
  readonly className?: string;
};

const GuardMeListFilter = ({ className }: GuardMeListFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    clearFilter,
    concluded,
    expired,
    hasFilter,
    inPanic,
    inProgress,
    setStatusFilter,
  } = useGuardMeTableControls();

  return (
    <Popover
      onOpenChange={setIsOpen}
      open={isOpen}
    >
      <PopoverTrigger asChild>
        <Button
          className={className}
          variant="outline"
        >
          {hasFilter ? (
            <Filter className="mr-2 h-4 w-4 text-primary fill-primary" />
          ) : (
            <Filter className="mr-2 h-4 w-4" />
          )}
          Filter
          <ChevronDown className={cn('ml-2 h-4 w-4', isOpen && 'rotate-180')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="w-[200px] p-0"
      >
        <Command>
          <CommandList>
            <CommandGroup heading="Status">
              <CommandItem
                onSelect={() => setStatusFilter('concluded', !concluded)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    concluded
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span>Concluded</span>
              </CommandItem>
              <CommandItem
                onSelect={() => setStatusFilter('expired', !expired)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    expired
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span>Expired</span>
              </CommandItem>
              <CommandItem
                onSelect={() => setStatusFilter('inPanic', !inPanic)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    inPanic
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span>In Panic</span>
              </CommandItem>
              <CommandItem
                onSelect={() => setStatusFilter('inProgress', !inProgress)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    inProgress
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className="h-4 w-4" />
                </div>
                <span>In Progress</span>
              </CommandItem>
            </CommandGroup>

            <CommandSeparator />
            <CommandGroup>
              <CommandItem
                className="justify-center text-center"
                disabled={!hasFilter}
                onSelect={() => clearFilter()}
              >
                Clear filters
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export { GuardMeListFilter };
