import { GuardMeActions } from '../components/GuardMeActions';
import { GuardMeAttachments } from '../components/GuardMeAttachments';
import { GuardMeAuditLog } from '../components/GuardMeAuditLog';
import { GuardMeDetails } from '../components/GuardMeDetails';
import { GuardMeLocation } from '../components/GuardMeLocation';
import { GuardMeMemberDetails } from '../components/GuardMeMemberDetails';
import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { ChevronLeft } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

const GuardMeQuery = graphql(`
  query GuardMeQuery($id: String!) {
    guardme(id: $id) {
      id
      shortId
      notes
      status
      completedAt
      createdAt
      endsAt
      updatedAt
      memberId
      totalAttachments
      member {
        id
        fullName
        displayName
        timezone
        avatarUrl
        phoneNumber
        emailAddress
        emergencyContacts {
          nodes {
            id
            phoneNumber
            description
          }
        }
      }
      organization {
        id
        memberMetadataFields
        settingIsRapidsosPanicButtonFlowEnabled
        settingIsRapidsosOperatorFlowEnabled
        settingRapidsosSocPhoneNumber
      }
      batteryLevel
      locationTimelineAsEncodedPolyline
      lastLocation {
        id
        createdAt
        coordinates {
          x
          y
        }
        geocode {
          id
          address
          plusCode
        }
      }
    }
  }
`);

const GuardMe = () => {
  const [currentMemberId, isGuardMeSupervisor] = useAppStore((state) => [
    state.activeMembership?.memberId,
    state.activeMembership?.isGuardMeSupervisor,
  ]);

  const { guardMeId } = useParams() as { guardMeId: string };

  const [{ data, fetching }] = useQuery({
    query: GuardMeQuery,
    variables: {
      id: guardMeId,
    },
  });

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div className="grid flex-1 auto-rows-max gap-4">
        <div className="flex items-center gap-4">
          <Link to="/guardme">
            <Button
              className="h-7 w-7"
              size="icon"
              variant="outline"
            >
              <ChevronLeft className="h-4 w-4" />
              <span className="sr-only">Back</span>
            </Button>
          </Link>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-3xl font-semibold tracking-tight sm:grow-0">
            GuardMe Session # {data?.guardme?.shortId?.split('-')[1]}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            {isGuardMeSupervisor &&
              currentMemberId &&
              data?.guardme?.memberId &&
              currentMemberId !== data.guardme.memberId && (
                <GuardMeActions guardmeId={guardMeId} />
              )}
          </div>
        </div>
        <div className="grid gap-4 lg:grid-cols-5 lg:gap-8">
          <div className="grid lg:grid-cols-2 auto-rows-max items-start gap-4 lg:col-span-3 lg:gap-8">
            <GuardMeDetails
              className="col-span-full"
              fetching={fetching}
              {...data?.guardme}
            />

            <GuardMeMemberDetails
              className="col-span-full"
              fetching={fetching}
              memberMetadataFields={
                data?.guardme?.organization?.memberMetadataFields
              }
              {...data?.guardme?.member}
            />

            <GuardMeAttachments
              className="col-span-full"
              fetching={fetching}
              guardmeId={guardMeId}
            />
          </div>

          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <GuardMeLocation
              className="col-span-full"
              fetching={fetching}
              locationTimelime={
                data?.guardme?.locationTimelineAsEncodedPolyline
              }
              member={data?.guardme?.member}
              {...data?.guardme?.lastLocation}
            />

            <GuardMeAuditLog
              fetching={fetching}
              guardmeId={guardMeId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { GuardMe };
