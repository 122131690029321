import { ClientError } from '@/components';
import { buttonVariants } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { ErrorBoundaryFallback } from '@/features/Misc';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import { ErrorBoundary } from '@sentry/react';
import { Zap } from 'lucide-react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

export const settingsNavItems = [
  {
    title: 'General',
    to: '/settings',
  },
  {
    isChild: true,
    isPremium: false,
    title: 'Map Settings',
    to: '/settings/map',
  },
  {
    title: 'Custom Metadata',
    to: '/settings/custom-metadata',
  },
  {
    title: 'Membership Codes',
    to: '/settings/membership-Codes',
  },
  {
    title: 'Check In Settings',
    to: '/settings/check-in',
  },
  {
    title: 'GuardMe Settings',
    to: '/settings/guardme',
  },
  {
    isChild: true,
    isPremium: true,
    title: '911 Integration',
    to: '/settings/guardme/911-integration',
  },
  {
    title: 'Incident Settings',
    to: '/settings/incidents',
  },
  {
    isChild: true,
    title: 'Incident types',
    to: '/settings/incidents/types',
  },
  {
    title: 'Places',
    to: '/settings/places',
  },
  {
    title: 'Bivy Stick Integration',
    to: '/settings/bivy-sticks',
  },
];

const OrganizationSettingsLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  if (!isAdmin) {
    return <ClientError code={401} />;
  }

  return (
    <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-5">
      <div className="w-full col-span-full flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 md:gap-8">
        <div className="md:hidden">
          <Select
            onValueChange={(value) => navigate(value)}
            value={pathname}
          >
            <SelectTrigger className="md:hidden w-full">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {settingsNavItems.map((item) => {
                return (
                  <SelectItem
                    key={item.to}
                    value={item.to}
                  >
                    {item.title}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </div>
        <div className="mx-auto grid w-full gap-2">
          <h1 className="text-3xl font-semibold">Settings</h1>
        </div>
        <div className="mx-auto grid w-full items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav className="grid text-sm text-muted-foreground">
            {settingsNavItems.map((item) => (
              <Link
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  pathname === item.to
                    ? 'bg-muted hover:bg-muted'
                    : 'hover:bg-transparent hover:underline',
                  'justify-start',
                  item.isChild && 'ml-4',
                )}
                key={item.to}
                to={item.to}
              >
                <span>{item.title}</span>
                {item.isPremium && (
                  <div className="ml-1 bg-primary text-primary-foreground px-2 py-1 rounded-full">
                    <Zap className="h-3 w-3" />
                  </div>
                )}
              </Link>
            ))}
          </nav>

          <div className="grid gap-6">
            <ErrorBoundary
              fallback={ErrorBoundaryFallback}
              key={pathname}
            >
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OrganizationSettingsLayout };
