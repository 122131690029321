import { IncidentListFilter } from '../components/IncidentListFilter';
import { IncidentPriority } from '../components/IncidentPriority';
import { IncidentStatus } from '../components/IncidentStatus';
import { IncidentViewFilter } from '../components/IncidentViewFilter';
import { useIncidentTableControls } from '../hooks/useIncidentTableControls';
import { DateRangePicker } from '@/components/DateRangePicker';
import { Datetime } from '@/components/Datetime';
import { TableSearch } from '@/components/TableSearch';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  TablePagnination,
  TableRowSkeleton,
} from '@/components/ui/TableWrapper';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Plus } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentsGql = graphql(`
  query IncidentsGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $shortId: String
    $createdAt_gte: Datetime
    $createdAt_lte: Datetime
    $contactMemberId: String
    $status: IncidentsStatusConditionInput
    $priority: IncidentsPriorityConditionInput
    $assignedToMeOrMyGroups: Boolean
    $types: [String!]
  ) {
    incidents(
      first: $first
      offset: $offset
      condition: {
        contactMemberId: $contactMemberId
        organizationId: $organizationId
        createdAt: { gte: $createdAt_gte, lte: $createdAt_lte }
        shortId: $shortId
        status: $status
        priority: $priority
        assignedToMeOrMyGroups: $assignedToMeOrMyGroups
        types: $types
      }
      orderBy: ID_DESC
    ) {
      totalCount
      nodes {
        id
        shortId
        subject
        description
        status
        priority
        createdAt
        updatedAt
        contactMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByMember {
          id
          fullName
          displayName
          avatarUrl
        }
        ownedByGroup {
          id
          name
        }
        incidentType {
          id
          name
          iconSvg
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  }
`);

const Incidents = () => {
  const [organizationId, memberId] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const navigate = useNavigate();

  const controls = useIncidentTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: IncidentsGql,
    variables: {
      assignedToMeOrMyGroups: controls.view === 'assignedToMyGroup',
      contactMemberId: controls.view === 'submittedByMe' ? memberId : undefined,
      createdAt_gte: controls.from?.toISOString(),
      createdAt_lte: controls.to?.toISOString(),
      first: controls.pageSize,
      offset: controls.offset,
      organizationId,
      priority: controls.hasFilter.priority ? controls.priority : null,
      shortId: controls.search,
      status: controls.hasFilter.status ? controls.status : null,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/incidents/${entityId}`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div>
        <div className="flex items-center">
          <TableSearch
            className="h-8"
            placeholder="Search by Id..."
            setSearchValue={controls.setSearch}
          />
          <div className="ml-auto flex items-center gap-2">
            <IncidentViewFilter className="h-8" />
            <IncidentListFilter className="h-8" />
            <DateRangePicker
              className="h-8"
              dateRange={controls.dateRange}
              setDateRange={controls.setDateRange}
            />
            <Link to="/incidents/new">
              <Button
                className="h-8 gap-1"
                size="sm"
              >
                <Plus className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  New Incident
                </span>
              </Button>
            </Link>
          </div>
        </div>

        <div className="mt-2">
          <Card x-chunk="guardme-sessions">
            <CardHeader>
              <CardTitle>Incidents</CardTitle>
              <CardDescription>
                Manage your organization incidents and view their status at a
                glance.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead>Id</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Subject</TableHead>
                    <TableHead>Priority</TableHead>
                    <TableHead className="text-center">Contact</TableHead>
                    <TableHead>Last Updated</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <MutationError error={error} />
                  {fetching && (
                    <TableRowSkeleton
                      cols={6}
                      rows={controls.pageSize / 3}
                    />
                  )}
                  {!fetching && data?.incidents?.nodes.length === 0 && (
                    <TableRow className="last:border-none hover:bg-inherit">
                      <TableCell
                        className="text-center h-24"
                        colSpan={8}
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                  {!fetching &&
                    data?.incidents?.nodes.map((item) => (
                      <TableRow
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <TableCell className="font-bold whitespace-nowrap">
                          <Link
                            className="hover:underline"
                            to={`/incidents/${item.id}`}
                          >
                            {item.shortId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <IncidentStatus status={item.status} />
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center">
                            <Badge
                              className="mr-2"
                              variant="secondary"
                            >
                              <img
                                alt={item.incidentType?.name}
                                className="h-4 w-4 mr-2"
                                src={`data:image/svg+xml;base64,${item.incidentType?.iconSvg}`}
                              />
                              {item.incidentType?.name}
                            </Badge>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <span className="font-bold overflow-hidden truncate max-w-20 lg:max-w-72 xl:max-w-md 2xl:max-w-lg">
                                  {item.subject}
                                </span>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>{item.subject}</p>
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell>
                          <IncidentPriority priority={item.priority} />
                        </TableCell>
                        <TableCell className="text-center">
                          <Tooltip>
                            <TooltipTrigger>
                              <Avatar className="h-6 w-6">
                                <AvatarImage
                                  src={
                                    item.contactMember?.avatarUrl ?? undefined
                                  }
                                />
                                <AvatarFallback>
                                  {item.contactMember?.fullName.slice(0, 2)}
                                </AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>
                            <TooltipContent side="left">
                              {item.contactMember?.displayName ??
                                item.contactMember?.fullName}
                            </TooltipContent>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Datetime datetime={item.updatedAt} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter>
              <TablePagnination
                currentPage={controls.page}
                getSearchParameters={controls.getPageSearchParameters}
                pageSize={controls.pageSize}
                totalCount={data?.incidents?.totalCount}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { Incidents };
