import { type ToggleProps } from './Toggle';
import {
  Children,
  cloneElement,
  forwardRef,
  type HTMLAttributes,
  type ReactElement,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

type ToggleGroupProps = HTMLAttributes<HTMLDivElement> & {
  children: Array<ReactElement<ToggleProps>> | ReactElement<ToggleProps>;
  onValueChange?: (value: string) => void;
  value?: string;
};

const ToggleGroup = forwardRef<HTMLDivElement, ToggleGroupProps>(
  (props, ref) => {
    const { value, onValueChange, children, className, ...extra } = props;

    const [selectedValue, setSelectedValue] = useState(value);

    const handleValueChange = (newValue: string) => {
      onValueChange?.(newValue);
      setSelectedValue(newValue);
    };

    return (
      <div
        {...extra}
        className={twMerge(
          'w-full flex flex-col lg:flex-row justify-start gap-2',
          'bg-gray-200 p-1.5 rounded-lg shadow-sm',
          className,
        )}
        ref={ref}
      >
        {Children.map(children, (child) =>
          cloneElement(child, {
            handleValueChange,
            selectedValue: value ?? selectedValue,
          }),
        )}
      </div>
    );
  },
);

export { ToggleGroup, type ToggleGroupProps };
