import { AdvancedMarker, Map, MapProvider } from '@/components/Map';
import { Card, CardContent } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';

type IncidentLocationProps = {
  readonly coordinates: {
    x: number;
    y: number;
  };
  readonly fetching: boolean;
  readonly geocode?:
    | {
        address: string;
        plusCode: string;
      }
    | null
    | undefined;
};

const IncidentLocation = ({
  coordinates,
  fetching,
  geocode,
}: IncidentLocationProps) => {
  return (
    <Card>
      {fetching && <Skeleton className="h-96 w-full" />}

      <MapProvider>
        {coordinates && (
          <Map
            className="h-96"
            defaultCenter={{
              lat: coordinates.y,
              lng: coordinates.x,
            }}
            defaultZoom={14}
          >
            <AdvancedMarker
              position={{
                lat: coordinates.y,
                lng: coordinates.x,
              }}
            />
          </Map>
        )}
      </MapProvider>

      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Incident Address (approximate)</div>
          <address className="grid gap-0.5 not-italic text-muted-foreground">
            {geocode?.address
              .split(',')
              .map((item) => <span key={item}>{item}</span>) ?? (
              <span>---</span>
            )}
          </address>
        </div>

        <Separator className="my-4" />

        <div className="grid gap-3">
          <div className="font-semibold">Coordinates</div>
          <ul className="grid gap-1">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Latitude</span>
              <span>{coordinates?.y}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Longitude</span>
              <span>{coordinates?.x}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Google Plus Code</span>
              <span>{geocode?.plusCode ?? '---'}</span>
            </li>
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};

export { IncidentLocation };
