import { useIncidentTableControls } from '../hooks/useIncidentTableControls';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';
import { Check, ListFilter } from 'lucide-react';

type Props = {
  readonly className?: string;
};

export const IncidentViewFilter = ({ className }: Props) => {
  const { setViewFilter, view } = useIncidentTableControls();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={cn('h-8 border', className)}
          size="sm"
          variant="outline"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          View
          <Separator
            className="mx-2 h-4"
            orientation="vertical"
          />
          {view === null && (
            <Badge
              className="rounded-sm px-1 font-normal"
              variant="secondary"
            >
              All Incidents
            </Badge>
          )}
          {view === 'assignedToMyGroup' && (
            <Badge
              className="rounded-sm px-1 font-normal"
              variant="secondary"
            >
              Assigned to My Group
            </Badge>
          )}
          {view === 'submittedByMe' && (
            <Badge
              className="rounded-sm px-1 font-normal"
              variant="secondary"
            >
              My Incidents
            </Badge>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-[200px] p-0"
      >
        <Command>
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              <CommandItem onSelect={() => setViewFilter('all')}>
                <span>All Incidents</span>
                <div
                  className={cn(
                    'ml-auto flex',
                    view === null
                      ? 'text-primary'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className={cn('h-4 w-4')} />
                </div>
              </CommandItem>
              <CommandItem onSelect={() => setViewFilter('submittedByMe')}>
                <span>My Incidents</span>
                <div
                  className={cn(
                    'ml-auto flex',
                    view === 'submittedByMe'
                      ? 'text-primary'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className={cn('h-4 w-4')} />
                </div>
              </CommandItem>
              <CommandItem onSelect={() => setViewFilter('assignedToMyGroup')}>
                <span>Assigned To My Group</span>
                <div
                  className={cn(
                    'ml-auto flex',
                    view === 'assignedToMyGroup'
                      ? 'text-primary'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <Check className={cn('h-4 w-4')} />
                </div>
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
