import { useTableControls } from '@/hooks/useTableControls';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useGuardMeTableControls = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const tableControls = useTableControls();

  const filter = searchParameters.getAll('filter');

  const concluded = filter.includes('concluded');
  const expired = filter.includes('expired');
  const inPanic = filter.includes('inPanic');
  const inProgress = filter.includes('inProgress');

  const setStatusFilter = useMemo(
    () =>
      (
        status: 'concluded' | 'expired' | 'inPanic' | 'inProgress',
        value: boolean,
      ) => {
        setSearchParameters((previous) => {
          if (value) {
            previous.append('filter', status);
          } else {
            previous.delete('filter', status);
          }

          return previous;
        });
      },
    [setSearchParameters],
  );

  const clearFilter = useMemo(
    () => () => {
      setSearchParameters((previous) => {
        previous.delete('filter');
        return previous;
      });
    },
    [setSearchParameters],
  );

  return {
    ...tableControls,
    clearFilter,
    concluded,
    expired,
    hasFilter: concluded || expired || inPanic || inProgress,
    inPanic,
    inProgress,
    setStatusFilter,
  };
};

export { useGuardMeTableControls };
