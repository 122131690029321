import { OrganizationSettingsLayout } from './components/OrganizationSettingsLayout';
import { BivySticks } from './routes/BivySticks';
import { CheckInSettings } from './routes/CheckInSettings';
import { CustomMetadata } from './routes/CustomMetadata';
import { GuardMeSettings } from './routes/GuardMeSettings';
import { IncidentSettings } from './routes/IncidentSettings';
import { IncidentTypes } from './routes/IncidentTypes';
import { MapSettings } from './routes/MapSettings';
import { MembershipCodes } from './routes/MembershipCodes';
import { PlaceCreate } from './routes/PlaceCreate';
import { PlaceEdit } from './routes/PlaceEdit';
import { Places } from './routes/Places';
import { RapidSosIntegration } from './routes/RapidSosIntegration';
import { Settings } from './routes/Settings';
import { type RouteObject } from 'react-router-dom';

export const organizationRoutes: RouteObject[] = [
  {
    children: [
      {
        Component: Settings,
        path: '/settings',
      },
      {
        Component: MapSettings,
        path: '/settings/map',
      },
      {
        Component: CustomMetadata,
        path: '/settings/custom-metadata',
      },
      {
        Component: GuardMeSettings,
        path: '/settings/guardme',
      },
      {
        Component: IncidentSettings,
        path: '/settings/incidents',
      },
      {
        Component: IncidentTypes,
        path: '/settings/incidents/types',
      },
      {
        Component: CheckInSettings,
        path: '/settings/check-in',
      },
      {
        Component: MembershipCodes,
        path: '/settings/membership-codes',
      },
      {
        Component: Places,
        path: '/settings/places',
      },
      {
        Component: PlaceCreate,
        path: '/settings/places/new',
      },
      {
        Component: PlaceEdit,
        path: '/settings/places/:placeId/edit',
      },
      {
        Component: RapidSosIntegration,
        path: '/settings/guardme/911-integration',
      },
      {
        Component: BivySticks,
        path: '/settings/bivy-sticks',
      },
    ],
    element: <OrganizationSettingsLayout />,
  },
];
