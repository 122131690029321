import { Badge } from '@/components/ui/badge';
import { type ButtonHTMLAttributes, type ElementType } from 'react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type ToggleProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick' | 'value'
> & {
  count?: number;
  handleValueChange?: (newValue: string) => void;
  icon?: ElementType;
  onClick?: (newValue: string) => void;
  selectedValue?: string;
  text: string;
  to?: string;
  value?: string;
};

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>((props, ref) => {
  const {
    className,
    count,
    handleValueChange,
    icon,
    onClick,
    selectedValue,
    text,
    to,
    value,
    ...extra
  } = props;

  const isActive = selectedValue === value;
  const Icon = icon;

  const activeClassNames = 'bg-white text-blue-500 font-semibold';
  const inActiveClassNames = 'bg-transparent hover:text-blue-500';

  if (to) {
    return (
      <Link to={to}>
        <button
          {...extra}
          className={twMerge(
            'w-full flex gap-1 items-center justify-center rounded-md ',
            'transition-all px-3 py-1 transition-duration-300',
            isActive ? activeClassNames : inActiveClassNames,
            className,
          )}
          onClick={() => {
            if (value) {
              handleValueChange?.(value);
              onClick?.(value);
            }
          }}
          ref={ref}
          type="button"
        >
          {Icon && (
            <Icon
              aria-hidden="true"
              className="h-5 w-5 opacity-70"
            />
          )}
          {text && <span className={twMerge('whitespace-nowrap')}>{text}</span>}
          {count !== undefined && <Badge variant="secondary">{count}</Badge>}
        </button>
      </Link>
    );
  }

  return (
    <button
      {...extra}
      className={twMerge(
        'w-full flex gap-1 items-center justify-center rounded-md ',
        'transition-all px-3 py-1 transition-duration-300',
        isActive ? activeClassNames : inActiveClassNames,
        className,
      )}
      onClick={() => {
        if (value) {
          handleValueChange?.(value);
          onClick?.(value);
        }
      }}
      ref={ref}
      type="button"
    >
      {Icon && (
        <Icon
          aria-hidden="true"
          className="h-5 w-5 opacity-70"
        />
      )}
      {text && <span className={twMerge('whitespace-nowrap')}>{text}</span>}
      {count !== undefined && <Badge variant="secondary">{count}</Badge>}
    </button>
  );
});

export { Toggle, type ToggleProps };
