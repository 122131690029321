import { Datetime } from '@/components/Datetime';
import {
  AdvancedMarker,
  Map,
  MapProvider,
  Pin,
  Polyline,
} from '@/components/Map';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import {
  type GeometryPoint,
  type GuardmeLocation,
  type GuardmeLocationGeocode,
  type Member,
} from '@/gql/graphql';

type GuardMeLocationProps = Partial<Pick<GuardmeLocation, 'createdAt'>> & {
  readonly className?: string;
  readonly coordinates?: Pick<GeometryPoint, 'x' | 'y'>;
  readonly fetching: boolean;
  readonly geocode?: Pick<
    GuardmeLocationGeocode,
    'address' | 'plusCode'
  > | null;
  readonly locationTimelime?: string | null;
  readonly member?: Pick<
    Member,
    'avatarUrl' | 'displayName' | 'fullName' | 'id'
  > | null;
};

const GuardMeLocation = ({
  className,
  coordinates,
  createdAt,
  fetching,
  geocode,
  locationTimelime,
  member,
}: GuardMeLocationProps) => {
  return (
    <Card className={className}>
      {fetching && <Skeleton className="h-96 w-full" />}

      <MapProvider>
        {coordinates && (
          <Map
            className="h-96"
            defaultCenter={{
              lat: coordinates.y,
              lng: coordinates.x,
            }}
            defaultZoom={14}
          >
            <AdvancedMarker
              position={{
                lat: coordinates.y,
                lng: coordinates.x,
              }}
            >
              <Pin size="avatar">
                <Avatar className="h-8 w-8">
                  <AvatarImage
                    alt={
                      member?.displayName ??
                      member?.fullName ??
                      'Deleted Member'
                    }
                    src={member?.avatarUrl ?? undefined}
                  />
                  <AvatarFallback>
                    {(
                      member?.displayName ??
                      member?.fullName ??
                      'Deleted Member'
                    ).slice(0, 2)}
                  </AvatarFallback>
                </Avatar>
              </Pin>
            </AdvancedMarker>
            {locationTimelime && (
              <Polyline
                encodedPath={locationTimelime}
                strokeColor="#1565C0"
              />
            )}
          </Map>
        )}
      </MapProvider>

      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Approximate Address</div>
          <address className="grid gap-0.5 not-italic text-muted-foreground">
            {geocode?.address
              .split(',')
              .map((item) => <span key={item}>{item}</span>) ?? (
              <span>---</span>
            )}
          </address>
        </div>

        <Separator className="my-4" />

        <div className="grid gap-3">
          <div className="font-semibold">Coordinates</div>
          <ul className="grid gap-1">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Latitude</span>
              <span>{coordinates?.y}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Longitude</span>
              <span>{coordinates?.x}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Google Plus Code</span>
              <span>{geocode?.plusCode ?? '---'}</span>
            </li>
          </ul>
        </div>
      </CardContent>

      <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
        <div className="text-xs text-muted-foreground">
          Updated{' '}
          {fetching ? (
            <span className="animate-pulse text-muted-foreground">---</span>
          ) : (
            <Datetime
              datetime={createdAt}
              variant="distance"
            />
          )}
        </div>
      </CardFooter>
    </Card>
  );
};

export { GuardMeLocation };
