import { Button } from './ui/button';
import { Input } from './ui/input';
import { cn } from '@/lib/utils';
import { useState } from 'react';

type TableSearchProps = {
  readonly className?: string;
  readonly containerClassName?: string;
  readonly placeholder?: string;
  readonly setSearchValue: (value: string) => void;
};

const TableSearch = ({
  className,
  containerClassName,
  placeholder,
  setSearchValue,
}: TableSearchProps) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <form
      className={cn(
        'flex w-full md:max-w-[250px] items-center space-x-2',
        containerClassName,
      )}
    >
      <Input
        className={className}
        onChange={(event) => setInputValue(event.target.value)}
        placeholder={placeholder || 'Search...'}
        value={inputValue}
      />
      <Button
        className={className}
        onClick={(event) => {
          event.preventDefault();
          setSearchValue(inputValue);
        }}
        type="submit"
      >
        Search
      </Button>
    </form>
  );
};

export { TableSearch };
